import { CellProps } from 'react-table';
import LinkCell from './LinkCell';

export default function PriorFiscalYearAdjustmentAffidavitDownloadCell<
  T extends { id?: number }
>(props: CellProps<T>) {
  return (
    <LinkCell to={'/api/prior-fiscal-year-adjustments/affidavit'} {...props} />
  );
}
