/** Generate by swagger-axios-codegen */
// @ts-nocheck
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AccountService {
  /**
   *
   */
  static getLoggedInUserDetails(options: IRequestOptions = {}): Promise<MeDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/me';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static login(
    params: {
      /** requestBody */
      body?: PasswordLoginCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static forgotPassword(
    params: {
      /** requestBody */
      body?: ForgotPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/forgotpassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static resetPassword(
    params: {
      /** requestBody */
      body?: ResetPasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/resetpassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changePassword(
    params: {
      /** requestBody */
      body?: ChangePasswordCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/changepassword';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static changeEmail(
    params: {
      /** requestBody */
      body?: ChangeEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/changeemail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static loginWith2Fa(
    params: {
      /** requestBody */
      body?: LoginWithTwoFaCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<StringApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/two-fa-login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static logout(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/logout';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confirmAccountRedirect(
    params: {
      /**  */
      email?: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/confirm-account-redirect';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { email: params['email'], token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static confirmAccount(
    params: {
      /**  */
      email?: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountConfirmationResultApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/account/confirm-account';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { email: params['email'], token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ChangeLogService {
  /**
   *
   */
  static getLogs(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EntityChangeListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/changelogs/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DeputyService {
  /**
   *
   */
  static getDeputyById(
    params: {
      /**  */
      deputyId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeputyCreateEditDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/deputies/{deputyId}';
      url = url.replace('{deputyId}', params['deputyId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteDeputyById(
    params: {
      /**  */
      deputyId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/deputies/{deputyId}';
      url = url.replace('{deputyId}', params['deputyId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateEditDeputyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeputyCreateEditDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/deputies';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CreateEditDeputyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeputyCreateEditDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/deputies';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDeputyListItems(
    params: {
      /** requestBody */
      body?: TreasuryDeputyDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeputyListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/deputies/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDeputiesCsv(
    params: {
      /** requestBody */
      body?: GetAllDeputiesDataTableCsvRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/deputies/export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDeputies(
    params: {
      /** requestBody */
      body?: SheriffDeputyDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeputyListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/deputies/sherifftable';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static activateDeputy(
    params: {
      /** requestBody */
      body?: ActivateDeputyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeputyCreateEditDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/deputies/activate';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deactivateDeputy(
    params: {
      /** requestBody */
      body?: DeactivateDeputyCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DeputyCreateEditDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/deputies/deactivate';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getDeputyOptionsByParishId(
    params: {
      /**  */
      parishId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/deputies/options/{parishId}';
      url = url.replace('{parishId}', params['parishId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FileResourcesService {
  /**
   *
   */
  static getBySasToken(
    params: {
      /**  */
      fileResourceGuid: string;
      /**  */
      token?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/file-resources/{fileResourceGuid}';
      url = url.replace('{fileResourceGuid}', params['fileResourceGuid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { token: params['token'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LaGovExportService {
  /**
   *
   */
  static generateLaGovExportFile(
    params: {
      /**  */
      monthUtc?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileResourceStreamResultApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/lagov/generate-lagov-file';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { MonthUtc: params['monthUtc'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class MobileConfigurationService {
  /**
   *
   */
  static checkForForcedUpdate(
    params: {
      /**  */
      version: string;
      /**  */
      platform: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/mobile-configuration/{version}/{platform}';
      url = url.replace('{version}', params['version'] + '');
      url = url.replace('{platform}', params['platform'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ParishService {
  /**
   *
   */
  static getParishById(
    params: {
      /**  */
      parishId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ParishDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/parishes/{parishId}';
      url = url.replace('{parishId}', params['parishId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteParishById(
    params: {
      /**  */
      parishId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/parishes/{parishId}';
      url = url.replace('{parishId}', params['parishId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ParishDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/parishes';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: ParishDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/parishes';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getParishes(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ParishDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/parishes/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getParishPaymentsByMonth(
    params: {
      /** requestBody */
      body?: ParishPaymentsByMonthDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ParishPaymentsByMonthListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/parishes/payments-by-month-table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getParishPaymentsByFiscalYear(
    params: {
      /** requestBody */
      body?: ParishPaymentsByFiscalYearDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ParishPaymentsByFiscalYearListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/parishes/payments-by-fiscal-year-table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getParishOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/parishes/options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PaymentRecordService {
  /**
   *
   */
  static getPaymentRecordById(
    params: {
      /**  */
      recordId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReconciliationDeputyDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/records/{recordId}';
      url = url.replace('{recordId}', params['recordId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static editExplanationById(
    params: {
      /** requestBody */
      body?: EditExplanationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/records/edit-explanation';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFullPayDataTable(
    params: {
      /** requestBody */
      body?: PaymentRecordsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentRecordListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/records/fullpay-table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPartialPayDataTable(
    params: {
      /** requestBody */
      body?: PaymentRecordsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PaymentRecordListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/records/partialpay-table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getOtherAdjustmentsDataTable(
    params: {
      /** requestBody */
      body?: PaymentRecordsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<OtherAdjustmentListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/records/adjustments-table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getNewDeputiesDataTable(
    params: {
      /** requestBody */
      body?: NewDeputiesDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<NewDeputyListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/records/new-deputies-table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRemovedDeputiesDataTable(
    params: {
      /** requestBody */
      body?: RemovedDeputiesCurrentMonthDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<RemovedDeputyListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/records/removed-deputies-table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadAffidavit(
    params: {
      /**  */
      id: number;
      /**  */
      affidavitFile: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/records/upload-affidavit';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['id']) {
        if (Object.prototype.toString.call(params['id']) === '[object Array]') {
          for (const item of params['id']) {
            data.append('Id', item as any);
          }
        } else {
          data.append('Id', params['id'] as any);
        }
      }

      if (params['affidavitFile']) {
        if (Object.prototype.toString.call(params['affidavitFile']) === '[object Array]') {
          for (const item of params['affidavitFile']) {
            data.append('AffidavitFile', item as any);
          }
        } else {
          data.append('AffidavitFile', params['affidavitFile'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteAffidavitById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/records/delete-affidavit/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static affidavitFileSas(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileResourceUriResultApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/records/affidavit/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PriorFiscalYearAdjustmentService {
  /**
   *
   */
  static getPriorFiscalYearAdjustmentsTable(
    params: {
      /** requestBody */
      body?: PriorFiscalYearAdjustmentDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PriorFiscalYearAdjustmentListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/prior-fiscal-year-adjustments/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPriorFiscalYearAdjustmentById(
    params: {
      /**  */
      priorFiscalYearAdjustmentId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PriorFiscalYearAdjustmentCreateEditDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/prior-fiscal-year-adjustments/{priorFiscalYearAdjustmentId}';
      url = url.replace('{priorFiscalYearAdjustmentId}', params['priorFiscalYearAdjustmentId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: CreateEditPriorFiscalYearAdjustmentCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PriorFiscalYearAdjustmentCreateEditDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/prior-fiscal-year-adjustments';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /** requestBody */
      body?: CreateEditPriorFiscalYearAdjustmentCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PriorFiscalYearAdjustmentCreateEditDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/prior-fiscal-year-adjustments';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPriorFiscalYearAdjustmentStatusOptions(
    options: IRequestOptions = {}
  ): Promise<StringOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/prior-fiscal-year-adjustments/prior-fiscal-year-adjustments-status-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePriorFiscalYearAdjustmentStatus(
    params: {
      /** requestBody */
      body?: UpdatePriorFiscalYearAdjustmentStatusCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/prior-fiscal-year-adjustments/update-status';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getReconciliationsByParish(
    params: {
      /**  */
      parishId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Int32OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/prior-fiscal-year-adjustments/reconciliations-by-parish';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { parishId: params['parishId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addPriorFiscalYearAdjustmentToReconciliation(
    params: {
      /** requestBody */
      body?: AddPriorFiscalYearAdjustmentToReconciliationCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/prior-fiscal-year-adjustments/add-prior-fiscal-year-adjustment-to-reconciliation';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static uploadAffidavit(
    params: {
      /**  */
      id: number;
      /**  */
      affidavitFile: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/prior-fiscal-year-adjustments/upload-affidavit';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['id']) {
        if (Object.prototype.toString.call(params['id']) === '[object Array]') {
          for (const item of params['id']) {
            data.append('Id', item as any);
          }
        } else {
          data.append('Id', params['id'] as any);
        }
      }

      if (params['affidavitFile']) {
        if (Object.prototype.toString.call(params['affidavitFile']) === '[object Array]') {
          for (const item of params['affidavitFile']) {
            data.append('AffidavitFile', item as any);
          }
        } else {
          data.append('AffidavitFile', params['affidavitFile'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static affidavitFileSas(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileResourceUriResultApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/prior-fiscal-year-adjustments/affidavit/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ReconciliationSubmissionService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: GenerateReconciliationSubmissionCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GenerateReconciliationSubmissionDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteReconciliationReport(
    params: {
      /** requestBody */
      body?: DeleteReconciliationSubmissionCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getReconciliationReport(
    params: {
      /**  */
      submissionId?: number;
      /**  */
      monthUtc?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReconciliationReportDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/reconciliation-report';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { SubmissionId: params['submissionId'], MonthUtc: params['monthUtc'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getReconciliationReportById(
    params: {
      /**  */
      submissionId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReconciliationReportDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/reconciliation-report/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static treasuryReconciliationReport(
    params: {
      /**  */
      submissionId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReconciliationReportDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/treasury-reconciliation-report/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static regenerateReconciliationReport(
    params: {
      /**  */
      submissionId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/regenerate-reconciliation-report/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createOtherAdjustment(
    params: {
      /** requestBody */
      body?: CreateEditOtherAdjustmentCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReconciliationDeputyDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/create-other-adjustment';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateOtherAdjustment(
    params: {
      /** requestBody */
      body?: CreateEditOtherAdjustmentCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReconciliationDeputyDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/update-other-adjustment';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateDaysWorked(
    params: {
      /** requestBody */
      body?: UpdateDaysWorkedCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReconciliationDeputyDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/update-days-worked';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateEndDate(
    params: {
      /** requestBody */
      body?: UpdateEndDateCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ReconciliationDeputyDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/update-end-date';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deletePaymentRecordById(
    params: {
      /**  */
      paymentRecordId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/{paymentRecordId}';
      url = url.replace('{paymentRecordId}', params['paymentRecordId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitForParishApproval(
    params: {
      /** requestBody */
      body?: SubmitForParishApprovalCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/submit-for-parish-approval';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sheriffApproverRejectSubmission(
    params: {
      /** requestBody */
      body?: SheriffApproverRejectSubmissionCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/sheriff-approver-reject-submission';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitForTreasuryApproval(
    params: {
      /** requestBody */
      body?: SubmitForTreasuryApprovalCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/submit-for-treasury-approval';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSubmissionStatusOptions(options: IRequestOptions = {}): Promise<StringOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/submission-status-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSheriffSubmissionStatusOptions(options: IRequestOptions = {}): Promise<StringOptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/sheriff-submission-status-options';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTreasurySubmissionTable(
    params: {
      /** requestBody */
      body?: TreasuryReconciliationSubmissionsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<TreasuryReconciliationSubmissionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/treasury-submission-table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTreasurySubmissionGrandTotal(
    params: {
      /**  */
      parishId?: number;
      /**  */
      monthUtc?: string;
      /**  */
      treasuryStatus?: TreasuryStatus;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DecimalApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/treasury-submission-table-grand-total';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        parishId: params['parishId'],
        monthUtc: params['monthUtc'],
        treasuryStatus: params['treasuryStatus']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSheriffSubmissionTable(
    params: {
      /** requestBody */
      body?: SheriffReconciliationSubmissionsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SheriffReconciliationSubmissionListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/sheriff-submission-table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static treasuryApproveSubmissionById(
    params: {
      /**  */
      submissionId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/treasury-approve/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static treasuryRejectSubmission(
    params: {
      /** requestBody */
      body?: TreasuryRejectSubmissionCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/treasury-reject';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submissionEventsDataTable(
    params: {
      /**  */
      id: number;
      /** requestBody */
      body?: SubmissionEventsDataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionEventListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/submission-events/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static certificateFileUpload(
    params: {
      /**  */
      id: number;
      /**  */
      certificateFile: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/upload-file';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['id']) {
        if (Object.prototype.toString.call(params['id']) === '[object Array]') {
          for (const item of params['id']) {
            data.append('Id', item as any);
          }
        } else {
          data.append('Id', params['id'] as any);
        }
      }

      if (params['certificateFile']) {
        if (Object.prototype.toString.call(params['certificateFile']) === '[object Array]') {
          for (const item of params['certificateFile']) {
            data.append('CertificateFile', item as any);
          }
        } else {
          data.append('CertificateFile', params['certificateFile'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static certificateFileDeleteById(
    params: {
      /**  */
      submissionId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/delete-file/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static certificateFileSas(
    params: {
      /**  */
      submissionId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FileResourceUriResultApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/certificateFile/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static downloadZip(
    params: {
      /**  */
      submissionId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/download-zip/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeDeputyFromReconciliation(
    params: {
      /**  */
      paymentRecordId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/reconciliations/remove-deputy/{paymentRecordId}';
      url = url.replace('{paymentRecordId}', params['paymentRecordId'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SettingsService {
  /**
   *
   */
  static getSettings(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSettingById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteSettingById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSettingByKey(
    params: {
      /**  */
      key: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SettingDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/{key}';
      url = url.replace('{key}', params['key'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createSetting(
    params: {
      /** requestBody */
      body?: SettingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateSetting(
    params: {
      /** requestBody */
      body?: SettingDto;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTreasurerName(options: IRequestOptions = {}): Promise<SettingDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/settings/treasurer-name';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class StatusService {
  /**
   *
   */
  static getStatusAsync(options: IRequestOptions = {}): Promise<StatusResultListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/status';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SubmissionNoteService {
  /**
   *
   */
  static getAllNotesForSubmission(
    params: {
      /**  */
      submissionId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubmissionNoteDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/notes/note/{submissionId}';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addSubmissionNote(
    params: {
      /** requestBody */
      body?: CreateSubmissionNoteCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/notes/add-note';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UsersService {
  /**
   *
   */
  static getProfilePhoto(
    params: {
      /**  */
      userId: number;
      /**  */
      profilePhotoGuid: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{userId}/profilePhoto/{profilePhotoGuid}';
      url = url.replace('{userId}', params['userId'] + '');
      url = url.replace('{profilePhotoGuid}', params['profilePhotoGuid'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUsers(
    params: {
      /** requestBody */
      body?: DataTableRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserListItemDtoDataTablePageApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/table';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUser(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postUserNoPhoto(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postUser(
    params: {
      /** requestBody */
      body?: CreateUserCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserDtoApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getRoleOptions(options: IRequestOptions = {}): Promise<OptionItemDtoListApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/roleOptions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendConfirmEmail(
    params: {
      /** requestBody */
      body?: SendConfirmAccountEmailCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<BooleanApiResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/users/sendConfirmEmail';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface AccountConfirmationResult {
  /**  */
  isPasswordResetRequired?: boolean;

  /**  */
  code?: string;
}

export interface AccountConfirmationResultApiResult {
  /**  */
  result?: AccountConfirmationResult;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ActivateDeputyCommand {
  /**  */
  id?: number;
}

export interface AddPriorFiscalYearAdjustmentToReconciliationCommand {
  /**  */
  priorFiscalYearAdjustmentId?: number;

  /**  */
  reconciliationId?: number;
}

export interface BooleanApiResult {
  /**  */
  result?: boolean;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ChangeEmailCommand {
  /**  */
  oldEmail?: string;

  /**  */
  newEmail?: string;

  /**  */
  currentPassword?: string;
}

export interface ChangePasswordCommand {
  /**  */
  password?: string;

  /**  */
  newPassword?: string;

  /**  */
  confirmNewPassword?: string;
}

export interface CreatedEntity {
  /**  */
  type?: string;

  /**  */
  id?: number;
}

export interface CreateEditDeputyCommand {
  /**  */
  id?: number;

  /**  */
  name?: Name;

  /**  */
  eligibilityDateUtc?: Date;

  /**  */
  endDateUtc?: Date;

  /**  */
  lastFourSSN?: string;

  /**  */
  overrideUniqueSSN?: boolean;
}

export interface CreateEditOtherAdjustmentCommand {
  /**  */
  id?: number;

  /**  */
  amountPaid?: number;

  /**  */
  explanation?: string;

  /**  */
  paymentForDeputyId?: number;

  /**  */
  reconciliationSubmissionId?: number;
}

export interface CreateEditPriorFiscalYearAdjustmentCommand {
  /**  */
  id?: number;

  /**  */
  deputyId?: number;

  /**  */
  amount?: number;

  /**  */
  explanation?: string;

  /**  */
  status?: PriorFiscalYearAdjustmentStatus;

  /**  */
  monthUtc?: Date;
}

export interface CreateSubmissionNoteCommand {
  /**  */
  submissionId?: number;

  /**  */
  note?: string;
}

export interface CreateUserCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  userRoleId?: number;

  /**  */
  profilePhoto?: any;

  /**  */
  parishId?: number;

  /**  */
  deactivated?: boolean;
}

export interface DataTableColumn {
  /**  */
  propertyName?: string;

  /**  */
  sortDirection?: string;

  /**  */
  searchTerm?: string;
}

export interface DataTableRequest {
  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface DeactivateDeputyCommand {
  /**  */
  id?: number;

  /**  */
  endDateUtc?: Date;
}

export interface DecimalApiResult {
  /**  */
  result?: number;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DeleteReconciliationSubmissionCommand {
  /**  */
  monthUtc?: Date;

  /**  */
  overrideRestriction?: boolean;
}

export interface Deputy {
  /**  */
  id?: number;

  /**  */
  name?: Name;

  /**  */
  eligibilityDateUtc?: Date;

  /**  */
  endDateUtc?: Date;

  /**  */
  createdDateUtc?: Date;

  /**  */
  lastFourSSN?: string;

  /**  */
  parishId?: number;

  /**  */
  parish?: Parish;
}

export interface DeputyCreateEditDto {
  /**  */
  id?: number;

  /**  */
  name?: Name;

  /**  */
  eligibilityDateUtc?: Date;

  /**  */
  endDateUtc?: Date;

  /**  */
  lastFourSSN?: string;
}

export interface DeputyCreateEditDtoApiResult {
  /**  */
  result?: DeputyCreateEditDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface DeputyListItemDto {
  /**  */
  id?: number;

  /**  */
  fullName?: string;

  /**  */
  eligibilityDateUtc?: Date;

  /**  */
  endDateUtc?: Date;

  /**  */
  lastFourSSN?: string;

  /**  */
  parishId?: number;

  /**  */
  parish?: string;

  /**  */
  isActive?: boolean;
}

export interface DeputyListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: DeputyListItemDto[];
}

export interface DeputyListItemDtoDataTablePageApiResult {
  /**  */
  result?: DeputyListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface EditExplanationCommand {
  /**  */
  id?: number;

  /**  */
  explanation?: string;
}

export interface EntityChangeListItemDto {
  /**  */
  id?: number;

  /**  */
  changeLogAction?: ChangeLogAction;

  /**  */
  entityTypeName?: string;

  /**  */
  entityId?: number;

  /**  */
  recordTypeName?: string;

  /**  */
  recordPropertyName?: string;

  /**  */
  oldValue?: string;

  /**  */
  newValue?: string;

  /**  */
  entityChangeTransactionTimestamp?: Date;

  /**  */
  entityChangeTransactionPrincipal?: string;
}

export interface EntityChangeListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: EntityChangeListItemDto[];
}

export interface EntityChangeListItemDtoDataTablePageApiResult {
  /**  */
  result?: EntityChangeListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface FileResource {
  /**  */
  id?: number;

  /**  */
  guid?: string;

  /**  */
  mimeType?: string;

  /**  */
  fileName?: string;

  /**  */
  resourceLocator?: string;

  /**  */
  resourceProviderType?: ResourceProviderType;

  /**  */
  createdTimestamp?: Date;

  /**  */
  deletedTimestamp?: Date;
}

export interface FileResourceStreamResult {
  /**  */
  fileStream?: Stream;

  /**  */
  cacheUntil?: Date;

  /**  */
  fileName?: string;

  /**  */
  mimeType?: string;
}

export interface FileResourceStreamResultApiResult {
  /**  */
  result?: FileResourceStreamResult;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface FileResourceUriResult {
  /**  */
  uri?: string;

  /**  */
  cacheUntil?: Date;
}

export interface FileResourceUriResultApiResult {
  /**  */
  result?: FileResourceUriResult;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface FiscalYearDto {
  /**  */
  fiscalYear?: string;

  /**  */
  amountPaid?: number;
}

export interface ForgotPasswordCommand {
  /**  */
  email?: string;
}

export interface GenerateReconciliationSubmissionCommand {
  /**  */
  priorFiscalYearAdjustments?: ReconciliationDeputy[];

  /**  */
  monthUtc?: Date;
}

export interface GenerateReconciliationSubmissionDto {
  /**  */
  id?: number;

  /**  */
  monthUtc?: Date;

  /**  */
  parishId?: number;

  /**  */
  fullPayRate?: number;

  /**  */
  partialPayRate?: number;

  /**  */
  createdByUserId?: number;

  /**  */
  createdDateUtc?: Date;

  /**  */
  lastModifiedByUserId?: number;

  /**  */
  lastModifiedDateUtc?: Date;
}

export interface GenerateReconciliationSubmissionDtoApiResult {
  /**  */
  result?: GenerateReconciliationSubmissionDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface GetAllDeputiesDataTableCsvRequest {
  /**  */
  tableEcho?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface Int32OptionItemDto {
  /**  */
  value?: number;

  /**  */
  label?: string;

  /**  */
  metadata?: any | null;
}

export interface Int32OptionItemDtoListApiResult {
  /**  */
  result?: Int32OptionItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface LoginWithTwoFaCommand {
  /**  */
  code?: string;

  /**  */
  rememberMe?: boolean;

  /**  */
  rememberMachine?: boolean;
}

export interface MeDto {
  /**  */
  signInResult?: string;

  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberConfirmed?: boolean;

  /**  */
  userRoleId?: number;

  /**  */
  userRole?: string;

  /**  */
  profilePhotoGuid?: string;

  /**  */
  parishId?: number;

  /**  */
  parishName?: string;

  /**  */
  deactivated?: boolean;
}

export interface MeDtoApiResult {
  /**  */
  result?: MeDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface Name {
  /**  */
  first?: string;

  /**  */
  middle?: string;

  /**  */
  last?: string;

  /**  */
  suffix?: string;
}

export interface NewDeputiesDataTableRequest {
  /**  */
  submissionId?: number;

  /**  */
  take?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface NewDeputyListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  affidavitFileFileName?: string;
}

export interface NewDeputyListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: NewDeputyListItemDto[];
}

export interface NewDeputyListItemDtoDataTablePageApiResult {
  /**  */
  result?: NewDeputyListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface OptionItemDto {
  /**  */
  value?: number;

  /**  */
  label?: string;

  /**  */
  metadata?: any | null;
}

export interface OptionItemDtoListApiResult {
  /**  */
  result?: OptionItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface OtherAdjustmentListItemDto {
  /**  */
  affidavitFileFileName?: string;

  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  eligibilityDateUtc?: Date;

  /**  */
  endDateUtc?: Date;

  /**  */
  amountPaid?: number;

  /**  */
  explanation?: string;

  /**  */
  reconciliationSubmissionId?: number;

  /**  */
  paymentForDeputyId?: number;

  /**  */
  deputyEligibility?: DeputyEligibilityType;
}

export interface OtherAdjustmentListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: OtherAdjustmentListItemDto[];
}

export interface OtherAdjustmentListItemDtoDataTablePageApiResult {
  /**  */
  result?: OtherAdjustmentListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface Parish {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  parishNum?: string;

  /**  */
  vendorNum?: string;
}

export interface ParishDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  parishNum?: string;

  /**  */
  vendorNum?: string;
}

export interface ParishDtoApiResult {
  /**  */
  result?: ParishDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ParishDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ParishDto[];
}

export interface ParishDtoDataTablePageApiResult {
  /**  */
  result?: ParishDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ParishPaymentsByFiscalYearDataTableRequest {
  /**  */
  startYear?: Date;

  /**  */
  endYear?: Date;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface ParishPaymentsByFiscalYearListItemDto {
  /**  */
  id?: number;

  /**  */
  parishName?: string;

  /**  */
  fiscalYears?: FiscalYearDto[];
}

export interface ParishPaymentsByFiscalYearListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ParishPaymentsByFiscalYearListItemDto[];
}

export interface ParishPaymentsByFiscalYearListItemDtoDataTablePageApiResult {
  /**  */
  result?: ParishPaymentsByFiscalYearListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ParishPaymentsByMonthDataTableRequest {
  /**  */
  monthUtc?: Date;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface ParishPaymentsByMonthListItemDto {
  /**  */
  id?: number;

  /**  */
  parishName?: string;

  /**  */
  amountPaid?: number;

  /**  */
  totalDeputies?: number;

  /**  */
  createdDateUtc?: Date;
}

export interface ParishPaymentsByMonthListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: ParishPaymentsByMonthListItemDto[];
}

export interface ParishPaymentsByMonthListItemDtoDataTablePageApiResult {
  /**  */
  result?: ParishPaymentsByMonthListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface PasswordLoginCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  rememberMe?: boolean;
}

export interface PaymentRecordListItemDto {
  /**  */
  daysWorked?: number;

  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  eligibilityDateUtc?: Date;

  /**  */
  endDateUtc?: Date;

  /**  */
  amountPaid?: number;

  /**  */
  explanation?: string;

  /**  */
  reconciliationSubmissionId?: number;

  /**  */
  paymentForDeputyId?: number;

  /**  */
  deputyEligibility?: DeputyEligibilityType;
}

export interface PaymentRecordListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: PaymentRecordListItemDto[];
}

export interface PaymentRecordListItemDtoDataTablePageApiResult {
  /**  */
  result?: PaymentRecordListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface PaymentRecordsDataTableRequest {
  /**  */
  submissionId?: number;

  /**  */
  deputyEligibilityType?: DeputyEligibilityType;

  /**  */
  take?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface PriorFiscalYearAdjustmentCreateEditDto {
  /**  */
  id?: number;

  /**  */
  deputyId?: number;

  /**  */
  parishId?: number;

  /**  */
  amount?: number;

  /**  */
  explanation?: string;

  /**  */
  status?: PriorFiscalYearAdjustmentStatus;

  /**  */
  monthUtc?: Date;
}

export interface PriorFiscalYearAdjustmentCreateEditDtoApiResult {
  /**  */
  result?: PriorFiscalYearAdjustmentCreateEditDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface PriorFiscalYearAdjustmentDataTableRequest {
  /**  */
  parishId?: number;

  /**  */
  priorFiscalYearAdjustmentStatus?: PriorFiscalYearAdjustmentStatus;

  /**  */
  monthUtc?: Date;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface PriorFiscalYearAdjustmentListItemDto {
  /**  */
  id?: number;

  /**  */
  deputyId?: number;

  /**  */
  parishId?: number;

  /**  */
  amount?: number;

  /**  */
  deputyName?: string;

  /**  */
  parishName?: string;

  /**  */
  explanation?: string;

  /**  */
  status?: PriorFiscalYearAdjustmentStatus;

  /**  */
  monthUtc?: Date;

  /**  */
  affidavitFileFileName?: string;
}

export interface PriorFiscalYearAdjustmentListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: PriorFiscalYearAdjustmentListItemDto[];
}

export interface PriorFiscalYearAdjustmentListItemDtoDataTablePageApiResult {
  /**  */
  result?: PriorFiscalYearAdjustmentListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ReconciliationDeputy {
  /**  */
  id?: number;

  /**  */
  name?: Name;

  /**  */
  eligibilityDateUtc?: Date;

  /**  */
  endDateUtc?: Date;

  /**  */
  parishId?: number;

  /**  */
  parish?: Parish;

  /**  */
  amountPaid?: number;

  /**  */
  daysWorked?: number;

  /**  */
  deputyEligibility?: DeputyEligibilityType;

  /**  */
  explanation?: string;

  /**  */
  reconciliationSubmissionId?: number;

  /**  */
  reconciliationSubmission?: ReconciliationSubmission;

  /**  */
  paymentForDeputyId?: number;

  /**  */
  paymentForDeputy?: Deputy;

  /**  */
  affidavitFileId?: number;

  /**  */
  affidavitFile?: FileResource;
}

export interface ReconciliationDeputyDto {
  /**  */
  id?: number;

  /**  */
  name?: Name;

  /**  */
  eligibilityDateUtc?: Date;

  /**  */
  endDateUtc?: Date;

  /**  */
  parishId?: number;

  /**  */
  amountPaid?: number;

  /**  */
  daysWorked?: number;

  /**  */
  deputyEligibility?: DeputyEligibilityType;

  /**  */
  explanation?: string;

  /**  */
  reconciliationSubmissionId?: number;

  /**  */
  paymentForDeputyId?: number;
}

export interface ReconciliationDeputyDtoApiResult {
  /**  */
  result?: ReconciliationDeputyDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ReconciliationReportDto {
  /**  */
  totalDeputiesPreviousMonth?: number;

  /**  */
  deputiesAdded?: number;

  /**  */
  deputiesRemoved?: number;

  /**  */
  parishName?: string;

  /**  */
  reconciliationDeputies?: ReconciliationDeputyDto[];

  /**  */
  id?: number;

  /**  */
  totalDeputiesCurrentMonth?: number;

  /**  */
  fullPayAmount?: number;

  /**  */
  partialPayAmount?: number;

  /**  */
  otherAdjustmentAmount?: number;

  /**  */
  subTotalAmountPaid?: number;

  /**  */
  totalAmountPaid?: number;

  /**  */
  fullPayDeputyCount?: number;

  /**  */
  partialPayDeputyCount?: number;

  /**  */
  otherAdjustmentCount?: number;

  /**  */
  sheriffStatus?: SubmissionAction;

  /**  */
  treasuryStatus?: SubmissionAction;

  /**  */
  hasAnyAffidavits?: boolean;

  /**  */
  hasCertificateFile?: boolean;

  /**  */
  certificateFileName?: string;

  /**  */
  monthUtc?: Date;
}

export interface ReconciliationReportDtoApiResult {
  /**  */
  result?: ReconciliationReportDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ReconciliationSubmission {
  /**  */
  id?: number;

  /**  */
  monthUtc?: Date;

  /**  */
  parishId?: number;

  /**  */
  parish?: Parish;

  /**  */
  eligibleDeputies?: ReconciliationDeputy[];

  /**  */
  fullPayRate?: number;

  /**  */
  partialPayRate?: number;

  /**  */
  createdByUserId?: number;

  /**  */
  createdByUser?: User;

  /**  */
  createdDateUtc?: Date;

  /**  */
  lastModifiedByUserId?: number;

  /**  */
  lastModifiedByUser?: User;

  /**  */
  lastModifiedDateUtc?: Date;

  /**  */
  submissionEvents?: SubmissionEvent[];

  /**  */
  submissionNotes?: SubmissionNote[];

  /**  */
  certificateFileId?: number;

  /**  */
  certificateFile?: FileResource;
}

export interface RemovedDeputiesCurrentMonthDataTableRequest {
  /**  */
  submissionId?: number;

  /**  */
  take?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface RemovedDeputyListItemDto {
  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  eligibilityDateUtc?: Date;

  /**  */
  endDateUtc?: Date;
}

export interface RemovedDeputyListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: RemovedDeputyListItemDto[];
}

export interface RemovedDeputyListItemDtoDataTablePageApiResult {
  /**  */
  result?: RemovedDeputyListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface ResetPasswordCommand {
  /**  */
  email?: string;

  /**  */
  password?: string;

  /**  */
  confirmPassword?: string;

  /**  */
  code?: string;
}

export interface Role {
  /**  */
  users?: UserRole[];

  /**  */
  roleClaims?: RoleClaim[];

  /**  */
  id?: number;

  /**  */
  name?: string;

  /**  */
  normalizedName?: string;

  /**  */
  concurrencyStamp?: string;
}

export interface RoleClaim {
  /**  */
  role?: Role;

  /**  */
  id?: number;

  /**  */
  roleId?: number;

  /**  */
  claimType?: string;

  /**  */
  claimValue?: string;
}

export interface SendConfirmAccountEmailCommand {
  /**  */
  email?: string;
}

export interface SettingDto {
  /**  */
  id?: number;

  /**  */
  key?: string;

  /**  */
  value?: string;

  /**  */
  isEnabled?: boolean;

  /**  */
  effectiveDateUtc?: Date;
}

export interface SettingDtoApiResult {
  /**  */
  result?: SettingDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SettingDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: SettingDto[];
}

export interface SettingDtoDataTablePageApiResult {
  /**  */
  result?: SettingDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SheriffApproverRejectSubmissionCommand {
  /**  */
  submissionId?: number;

  /**  */
  comment?: string;
}

export interface SheriffDeputyDataTableRequest {
  /**  */
  deputyStatus?: string;

  /**  */
  take?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface SheriffReconciliationSubmissionListItemDto {
  /**  */
  id?: number;

  /**  */
  monthUtc?: Date;

  /**  */
  sheriffStatus?: SheriffStatus;
}

export interface SheriffReconciliationSubmissionListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: SheriffReconciliationSubmissionListItemDto[];
}

export interface SheriffReconciliationSubmissionListItemDtoDataTablePageApiResult {
  /**  */
  result?: SheriffReconciliationSubmissionListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SheriffReconciliationSubmissionsDataTableRequest {
  /**  */
  parishId?: number;

  /**  */
  sheriffStatus?: SheriffStatus;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface StatusResult {
  /**  */
  name?: string;

  /**  */
  status?: string;
}

export interface StatusResultListApiResult {
  /**  */
  result?: StatusResult[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface Stream {
  /**  */
  canRead?: boolean;

  /**  */
  canWrite?: boolean;

  /**  */
  canSeek?: boolean;

  /**  */
  canTimeout?: boolean;

  /**  */
  length?: number;

  /**  */
  position?: number;

  /**  */
  readTimeout?: number;

  /**  */
  writeTimeout?: number;
}

export interface StringApiResult {
  /**  */
  result?: string;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface StringOptionItemDto {
  /**  */
  value?: string;

  /**  */
  label?: string;

  /**  */
  metadata?: any | null;
}

export interface StringOptionItemDtoListApiResult {
  /**  */
  result?: StringOptionItemDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SubmissionEvent {
  /**  */
  id?: number;

  /**  */
  userId?: number;

  /**  */
  user?: User;

  /**  */
  comment?: string;

  /**  */
  timestampUtc?: Date;

  /**  */
  submissionAction?: SubmissionAction;

  /**  */
  stage?: ReviewStage;

  /**  */
  reconciliationSubmissionId?: number;
}

export interface SubmissionEventListItemDto {
  /**  */
  id?: number;

  /**  */
  userUserName?: string;

  /**  */
  comment?: string;

  /**  */
  timestampUtc?: Date;

  /**  */
  submissionAction?: SubmissionAction;

  /**  */
  stage?: ReviewStage;

  /**  */
  event?: string;
}

export interface SubmissionEventListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: SubmissionEventListItemDto[];
}

export interface SubmissionEventListItemDtoDataTablePageApiResult {
  /**  */
  result?: SubmissionEventListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SubmissionEventsDataTableRequest {
  /**  */
  id?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface SubmissionNote {
  /**  */
  id?: number;

  /**  */
  emailAddress?: string;

  /**  */
  createdDate?: Date;

  /**  */
  note?: string;

  /**  */
  reconciliationSubmissionId?: number;
}

export interface SubmissionNoteDto {
  /**  */
  emailAddress?: string;

  /**  */
  createdDate?: Date;

  /**  */
  note?: string;
}

export interface SubmissionNoteDtoListApiResult {
  /**  */
  result?: SubmissionNoteDto[];

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface SubmitForParishApprovalCommand {
  /**  */
  submissionId?: number;
}

export interface SubmitForTreasuryApprovalCommand {
  /**  */
  submissionId?: number;
}

export interface TreasuryDeputyDataTableRequest {
  /**  */
  parishId?: number;

  /**  */
  deputyStatus?: string;

  /**  */
  take?: number;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface TreasuryReconciliationSubmissionListItemDto {
  /**  */
  parishName?: string;

  /**  */
  monthUtc?: Date;

  /**  */
  id?: number;

  /**  */
  totalDeputiesCurrentMonth?: number;

  /**  */
  fullPayAmount?: number;

  /**  */
  partialPayAmount?: number;

  /**  */
  otherAdjustmentAmount?: number;

  /**  */
  subTotalAmountPaid?: number;

  /**  */
  totalAmountPaid?: number;

  /**  */
  fullPayDeputyCount?: number;

  /**  */
  partialPayDeputyCount?: number;

  /**  */
  otherAdjustmentCount?: number;

  /**  */
  sheriffStatus?: SubmissionAction;

  /**  */
  treasuryStatus?: SubmissionAction;

  /**  */
  hasAnyAffidavits?: boolean;

  /**  */
  hasCertificateFile?: boolean;

  /**  */
  certificateFileName?: string;
}

export interface TreasuryReconciliationSubmissionListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: TreasuryReconciliationSubmissionListItemDto[];
}

export interface TreasuryReconciliationSubmissionListItemDtoDataTablePageApiResult {
  /**  */
  result?: TreasuryReconciliationSubmissionListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface TreasuryReconciliationSubmissionsDataTableRequest {
  /**  */
  monthUtc?: Date;

  /**  */
  parishId?: number;

  /**  */
  treasuryStatus?: TreasuryStatus;

  /**  */
  tableEcho?: number;

  /**  */
  skip?: number;

  /**  */
  take?: number;

  /**  */
  allSearch?: string;

  /**  */
  columns?: DataTableColumn[];

  /**  */
  includeColumns?: boolean;
}

export interface TreasuryRejectSubmissionCommand {
  /**  */
  id?: number;

  /**  */
  comment?: string;
}

export interface UpdateDaysWorkedCommand {
  /**  */
  id?: number;

  /**  */
  daysWorked?: number;

  /**  */
  explanation?: string;
}

export interface UpdateEndDateCommand {
  /**  */
  id?: number;

  /**  */
  explanation?: string;

  /**  */
  endDateUtc?: Date;
}

export interface UpdatePriorFiscalYearAdjustmentStatusCommand {
  /**  */
  id?: number;

  /**  */
  status?: PriorFiscalYearAdjustmentStatus;
}

export interface UpdateUserCommand {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  username?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  userRoleId?: number;

  /**  */
  profilePhoto?: any;

  /**  */
  parishId?: number;

  /**  */
  deactivated?: boolean;
}

export interface User {
  /**  */
  userRoles?: UserRole[];

  /**  */
  userTokens?: UserToken[];

  /**  */
  userLogins?: UserLogin[];

  /**  */
  userClaims?: UserClaim[];

  /**  */
  profilePhotoId?: number;

  /**  */
  profilePhoto?: FileResource;

  /**  */
  parishId?: number;

  /**  */
  parish?: Parish;

  /**  */
  twoFactorLastGeneratedUtc?: Date;

  /**  */
  twoFactorLoginAttempts?: number;

  /**  */
  newlyImported?: boolean;

  /**  */
  deactivated?: boolean;

  /**  */
  id?: number;

  /**  */
  userName?: string;

  /**  */
  normalizedUserName?: string;

  /**  */
  email?: string;

  /**  */
  normalizedEmail?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  passwordHash?: string;

  /**  */
  securityStamp?: string;

  /**  */
  concurrencyStamp?: string;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberConfirmed?: boolean;

  /**  */
  twoFactorEnabled?: boolean;

  /**  */
  lockoutEnd?: Date;

  /**  */
  lockoutEnabled?: boolean;

  /**  */
  accessFailedCount?: number;
}

export interface UserClaim {
  /**  */
  user?: User;

  /**  */
  id?: number;

  /**  */
  userId?: number;

  /**  */
  claimType?: string;

  /**  */
  claimValue?: string;
}

export interface UserDto {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberConfirmed?: boolean;

  /**  */
  userRoleId?: number;

  /**  */
  userRole?: string;

  /**  */
  profilePhotoGuid?: string;

  /**  */
  parishId?: number;

  /**  */
  parishName?: string;

  /**  */
  deactivated?: boolean;
}

export interface UserDtoApiResult {
  /**  */
  result?: UserDto;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface UserListItemDto {
  /**  */
  id?: number;

  /**  */
  email?: string;

  /**  */
  emailConfirmed?: boolean;

  /**  */
  phoneNumber?: string;

  /**  */
  phoneNumberConfirmed?: boolean;

  /**  */
  parishName?: string;

  /**  */
  isActive?: boolean;
}

export interface UserListItemDtoDataTablePage {
  /**  */
  totalFilteredRecords?: number;

  /**  */
  totalRecords?: number;

  /**  */
  rows?: UserListItemDto[];
}

export interface UserListItemDtoDataTablePageApiResult {
  /**  */
  result?: UserListItemDtoDataTablePage;

  /**  */
  validationFailures?: ValidationError[];

  /**  */
  createdEntities?: CreatedEntity[];

  /**  */
  hasErrors?: boolean;
}

export interface UserLogin {
  /**  */
  user?: User;

  /**  */
  loginProvider?: string;

  /**  */
  providerKey?: string;

  /**  */
  providerDisplayName?: string;

  /**  */
  userId?: number;
}

export interface UserRole {
  /**  */
  user?: User;

  /**  */
  role?: Role;

  /**  */
  userId?: number;

  /**  */
  roleId?: number;
}

export interface UserToken {
  /**  */
  user?: User;

  /**  */
  userId?: number;

  /**  */
  loginProvider?: string;

  /**  */
  name?: string;

  /**  */
  value?: string;
}

export interface ValidationError {
  /**  */
  propertyName?: string;

  /**  */
  errorMessage?: string;
}

export enum ChangeLogAction {
  'Unknown' = 'Unknown',
  'Deleted' = 'Deleted',
  'Modified' = 'Modified',
  'Added' = 'Added'
}

export enum DeputyEligibilityType {
  'FullPay' = 'FullPay',
  'PartialPay' = 'PartialPay',
  'OtherAdjustment' = 'OtherAdjustment',
  'PriorFiscalYearAdjustment' = 'PriorFiscalYearAdjustment'
}

export enum PriorFiscalYearAdjustmentStatus {
  'Pending' = 'Pending',
  'Rejected' = 'Rejected',
  'Approved' = 'Approved',
  'Reconciled' = 'Reconciled'
}

export enum ResourceProviderType {
  'Unknown' = 'Unknown',
  'FileSystem' = 'FileSystem',
  'BlobStorage' = 'BlobStorage'
}

export enum ReviewStage {
  'SheriffReview' = 'SheriffReview',
  'TreasuryReview' = 'TreasuryReview'
}

export enum SheriffStatus {
  'Open' = 'Open',
  'Pending' = 'Pending',
  'Rejected' = 'Rejected',
  'Approved' = 'Approved',
  'Missing' = 'Missing'
}

export enum SubmissionAction {
  'Submitted' = 'Submitted',
  'Rejected' = 'Rejected',
  'Approved' = 'Approved'
}

export enum TreasuryStatus {
  'NA' = 'NA',
  'Pending' = 'Pending',
  'Rejected' = 'Rejected',
  'Approved' = 'Approved'
}
